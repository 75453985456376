
import { defineComponent, ref, provide } from 'vue'
import ReportLineChart from '@/midone/components/report-line-chart/Main.vue'

export default defineComponent({
  components: {
    ReportLineChart
  },
  setup () {
    const announcementRef = ref<HTMLElement>()
    const newProjectsRef = ref<HTMLElement>()
    const todaySchedulesRef = ref<HTMLElement>()

    provide('bind[announcementRef]', (el: HTMLElement) => {
      announcementRef.value = el
    })

    provide('bind[newProjectsRef]', (el: HTMLElement) => {
      newProjectsRef.value = el
    })

    provide('bind[todaySchedulesRef]', (el: HTMLElement) => {
      todaySchedulesRef.value = el
    })

    const prevAnnouncement = () => {
      const el: any = announcementRef.value
      el.tns.goTo('prev')
    }
    const nextAnnouncement = () => {
      const el: any = announcementRef.value
      el.tns.goTo('next')
    }
    const prevNewProjects = () => {
      const el: any = newProjectsRef.value
      el.tns.goTo('prev')
    }
    const nextNewProjects = () => {
      const el: any = newProjectsRef.value
      el.tns.goTo('next')
    }
    const prevTodaySchedules = () => {
      const el: any = todaySchedulesRef.value
      el.tns.goTo('prev')
    }
    const nextTodaySchedules = () => {
      const el: any = todaySchedulesRef.value
      el.tns.goTo('next')
    }

    return {
      prevAnnouncement,
      nextAnnouncement,
      prevNewProjects,
      nextNewProjects,
      prevTodaySchedules,
      nextTodaySchedules
    }
  }
})
